import moment from 'moment-timezone'

export function utcStringToLocal(utcString) {

    console.log('convert date from: %s', utcString)

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    console.log('timezone: %s', timezone)

    const timeUtc = moment.utc(utcString)

    const timeLocal = timeUtc.tz(timezone);

    console.log('localtime: %s', timeLocal.format())

    return timeLocal.format('YYYY-MM-DD HH:mm')
}