<template>

  <div class="col">
    <div class="card radius-10 overflow-hidden">
      <div class="card-body card-body-statbox1">
        <div class="d-flex align-items-center">
          <div>
            <p class="mb-0">{{title}}</p>
          </div>
        </div>
      </div>

      <apexchart type="donut" height="180px" :options="chartOptions" :series="series"></apexchart>

    </div>
  </div>

</template>

<script>

export default {
  props: [
    'title',
    'dataNode'
  ],

  mounted() {
    this.updateValues()

  },

  watch: {
    dataNode(){
      if (this.dataNode != undefined){
        console.log('values updated', this.dataNode)
        this.updateValues()
      }
    }
  },

  methods: {
    updateValues: function () {
      if (this.dataNode != undefined){
        console.log('Pie chart with:', this.dataNode)
        this.series = this.dataNode.series
        this.chartOptions.colors = this.dataNode.colors
        this.chartOptions.labels = this.dataNode.labels
      }
    }
  },

  data() {
    return {

      series: [],

      chartOptions: {
        colors: ['#dddddd'], // to avoid nullpointer exception
        chart: {
          type: 'donut'
        },
        plotOptions: {
          pie: {
            donut: {
              size: '60%'
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            var intvalue = Math.round( val );
            return intvalue + "%"
          },
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 1
          }
        },
        fill: {
          type: 'gradient',
        },

      },



    }
  },

}


</script>