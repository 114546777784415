<template>

  <div class="col">
    <div class="card radius-10 overflow-hidden">
      <div class="card-body card-body-statbox1">
        <div class="d-flex align-items-center">
          <div>
            <p class="mb-0">{{title}}</p>
          </div>
        </div>
      </div>

      <apexchart type="radar" height="300px" :options="chartOptions" :series="series"></apexchart>

    </div>
  </div>

</template>

<script>

export default {
  props: [
    'title',
    'dataNode',
    'color'
  ],

  mounted() {
    this.updateValues()

  },

  watch: {
    dataNode(){
      if (this.dataNode != undefined){
        console.log('values updated', this.dataNode)
        this.updateValues()
      }
    }
  },

  methods: {
    updateValues: function () {
      if (this.dataNode != undefined){
        console.log('Radar with:', this.dataNode)
        this.series[0].data = this.dataNode.data
        this.chartOptions.xaxis.categories = this.dataNode.labels
        this.chartOptions.theme.monochrome.color = this.color
      }
    }
  },

  data() {
    return {

      series: [{
        name: 'Series 1',
        data: [],
      }],

      chartOptions: {

        chart: {
          height: '100%',
          width: '100%',
          type: 'radar',
          parentHeightOffset: 0,
          toolbar: {
            show: false
          },
        },
        title: {
          // text: 'Basic2 Radar Chart'
        },
        xaxis: {
          categories: ['January', 'February', 'March']
        },
        theme: {
          mode: 'light',
          palette: 'palette2',
          monochrome: {
            enabled: true,
            color: '#4b4b4b',
            shadeTo: 'light',
            shadeIntensity: 0.65
          },
        },
      },



    }
  },

}


</script>