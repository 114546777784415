<template>

  <div class="col">
    <div class="card radius-10 overflow-hidden">
      <div class="card-body card-body-statbox1">
        <div class="d-flex align-items-center">
          <div>
            <p class="mb-0">{{title}}</p>
          </div>
        </div>
      </div>

      <apexchart type="area" height="80px" :options="chartOptions" :series="series"></apexchart>

    </div>
  </div>

</template>

<script>

export default {
  props: [
    'values',
    'title',
    'color',
  ],

  mounted() {
    this.updateValues()

    if (this.color != undefined){
      this.chartOptions.theme.monochrome.color = this.color
    }
  },

  watch: {
    values(){
      console.log('values updated', this.values)
      this.updateValues()
    }
  },

  methods: {
    updateValues: function () {
      if (this.values != undefined){
        this.series[0].data = this.values
      }
    }
  },

  data() {
    return {

      series: [{
        name: '%',
        data: [0],
      }],

      chartOptions: {
        chart: {
          height: 'auto',
          width: '100%',
          type: 'area',
          parentHeightOffset: 0,
          toolbar: {
            show: false
          },
        },
        grid: {
          show: false,
          padding: {
            top: -26,
            left: -10,
            right: 0,
            bottom: -14,
          }
        },
        xaxis:{
          labels: {
            show: false
          }
        },
        yaxis:{
          labels: {
            show: false
          },
          min: 0,
          max: 100,

        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        tooltip: {

        },
        theme: {
          mode: 'light',
          palette: 'palette2',
          monochrome: {
            enabled: true,
            color: '#0e6f05',
            shadeTo: 'light',
            shadeIntensity: 0.65
          },
        },
      },



    }
  },

}


</script>